<template>
  <div>
    <!-- Sidebar for Add/Edit Form -->
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ sidebarTitle }}</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Exam Group Name"
              invalid-feedback="Name is required"
              ref="name"
            >
              <b-form-input
                placeholder="Enter exam group name"
                v-model="myObj.name"
                @input="CheckName()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Exam Types"
              invalid-feedback="At least one exam type is required"
              ref="examTypes"
            >
              <v-select
                multiple
                :closeOnSelect="false"
                v-model="myObj.examTypes"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="examTypeOptions"
                label="examType"
                :reduce="(val) => val.id"
                :clearable="false"
                placeholder="Select exam types"
                @input="CheckExamTypes()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>{{ sidebarButton }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-sidebar>

    <!-- Main Content -->
    <b-card>
      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="12" cols="12">
          <b-button
            @click="AddOpen()"
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Add Exam Group</span>
          </b-button>
        </b-col>

        <b-col class="mt-md-0 mt-1" xl="10" lg="9" md="8" sm="12" cols="12">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input v-model="searchQuery" placeholder="Search..." />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Table -->
      <b-table
        :items="filteredItems"
        :fields="fields"
        responsive
        show-empty
        :busy="loading"
        class="position-relative mt-2"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #cell(examTypes)="data">
          <b-badge
            v-for="exm in data.item.examLabels.split(',')"
            :key="exm"
            variant="light-primary"
            class="mr-1"
          >
            <span>{{ exm }}</span>
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              v-if="rights.edit"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click="EditOpen(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-if="rights.delete"
              variant="outline-danger"
              class="btn-icon"
              @click="Delete(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BSpinner,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [mapActions],
  data() {
    return {
      loading: false,
      request: false,
      visibility: false,
      searchQuery: "",
      editing: false,
      examTypeOptions: [],
      rights: {
        add: true,
        edit: true,
        delete: true,
      },
      fields: [
        { key: "name", label: "Group Name", sortable: true },
        { key: "examTypes", label: "Exam Types" },
        { key: "actions", label: "Actions" },
      ],
      items: [
        {
          id: 1,
          name: "First Term Exams",
          examTypes: [1, 2],
          examLabels: "Mid Term,Final Term",
        },
        {
          id: 2,
          name: "Second Term Exams",
          examTypes: [1, 3],
          examLabels: "Mid Term,Unit Test",
        },
        {
          id: 3,
          name: "Annual Exams",
          examTypes: [2, 3],
          examLabels: "Final Term,Unit Test",
        },
        {
          id: 4,
          name: "Practice Tests",
          examTypes: [3],
          examLabels: "Unit Test",
        },
        {
          id: 5,
          name: "Mock Exams",
          examTypes: [1, 2, 3],
          examLabels: "Mid Term,Final Term,Unit Test",
        },
      ],
      myObj: {
        id: 0,
        name: "",
        examTypes: [],
      },
    };
  },
  computed: {
    sidebarTitle() {
      return this.editing ? "Edit Exam Group" : "Add Exam Group";
    },
    sidebarButton() {
      return this.editing ? "Update" : "Add";
    },
    filteredItems() {
      return this.items.filter((item) =>
        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  created() {
    this.LoadExamTypes();
    // this.LoadData();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async LoadExamTypes() {
      const response = await this.get({
        url:
          this.$store.state.domain +
          "ExamTypes?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
      this.examTypeOptions = response;
    },
    async LoadData() {
      this.loading = true;
      this.items = await this.get({
        url:
          this.$store.state.domain +
          "examGroups?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
      this.loading = false;
    },

    AddOpen() {
      this.editing = false;
      this.myObj = {
        id: 0,
        name: "",
        examTypes: [],
      };
      this.visibility = true;
    },

    EditOpen(item) {
      this.editing = true;
      this.myObj = { ...item };
      this.visibility = true;
    },

    hideSideBar() {
      this.visibility = false;
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name.trim() === "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    CheckExamTypes() {
      var elem = this.$refs["examTypes"];
      if (!this.myObj.examTypes || this.myObj.examTypes.length === 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    async Add() {
      this.CheckName();
      this.CheckExamTypes();

      if (this.CheckName() === false || this.CheckExamTypes() === false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }

      this.request = true;
      const status = await (this.editing ? this.put : this.post)({
        url:
          this.$store.state.domain +
          "examGroups/" +
          this.myObj.id +
          "?db=" +
          this.$store.state.userData.db,
        body: this.myObj,
        message: `Exam group ${
          this.editing ? "updated" : "added"
        } successfully.`,
        context: this,
        token: this.$store.state.userData.token,
      });

      if (status) {
        this.hideSideBar();
        this.LoadData();
      }
      this.request = false;
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        const status = await this.delete({
          url: this.$store.state.domain + "examGroups/" + id,
          body: null,
          message: "Exam group removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });

        if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
